<template>
  <v-container fluid>
      <v-row>
          <v-col>
            <v-dialog
            v-model="dialog"
            width="700"
            
            transition="dialog-bottom-transition"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on" @click="getItemsReference()"><v-icon>mdi-plus</v-icon> زیادکردنی تەلەف</v-btn>
            </template>
            <v-card>
                <v-toolbar
                dark
                outlined
                :class="(isUpdate?'orange accent-2':'blue accent-2')"
                elevation="1"
                >
                <v-btn
                    icon 
                    @click="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn  text @click="isUpdate? update():create()" >
                    <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'نوێکردنەوەی تەلەف': 'زیادکردنی تەلەف'}} </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <v-form ref="disposal">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" lg="6" sm="12">
                               <v-autocomplete :items="itemReference" item-text="item_name" item-value="item_id" dense outlined label="کاڵا" v-model="disposal.item_id"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.numberRule" dense label="بڕ بە یەکە" outlined v-model="disposal.qty_unit" type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="6" sm="12">
                                <v-text-field @focus="$event.target.select()" :rules="rules.numberRule" dense label="بڕ بە دانە" outlined v-model="disposal.qty_item" type="number"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
            </v-dialog>
            <v-card>
                <v-data-table :headers="headers" :items="disposals" hide-default-footer  :items-per-page="10"  :loading="loading" no-data-text="هیچ تەلەفێک نییە نییە">
                    <template v-slot:top>
                        <v-text-field @focus="$event.target.select()"
                        v-model="table.search"
                        label="گەڕان"
                        class="mx-4"
                        ></v-text-field>
                    </template>
                    
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                        <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="disposal = {...removeProperty(item, ['item_name', 'unit_id', 'unit_name'])}; dialog=true;getItemsReference()">mdi-pencil</v-icon>
                        <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                        </v-hover>
                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.disposal_id)">mdi-delete</v-icon>
                            <v-icon large class="ml-2" v-else >mdi-delete</v-icon>
                        </v-hover>
                    </template>
                </v-data-table>
                <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfdisposals / 20)"  v-model="table.page"></v-pagination>
            </v-card>
          </v-col>
      </v-row>
      <notification/>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfdisposals: 0,
                search: ''
            },
            dialog: false,
            menu2: false,
            memberReference: [],
            itemReference: [],
            disposal: {
                disposal_id: 0,
                item_id: 0,
                qty_unit: 0,
                detail: '',
                qty_item: 0,
            },
            loading: false,
            disposals: [],
            headers: [
                {text: 'ناوی کاڵا', value: 'item_name', align: 'center'},
                {text: 'ڕوونکردنەوە', value: 'detail', align: 'center'},
                {text: 'بڕ بە یەکە', value: 'qty_unit', align: 'center'},
                {text: 'بڕ بە دانە', value: 'qty_item', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return ( v != '' || 'تکایە ئەم خانەیە پڕبکەرەوە')
                    }
                ],
                numberRule: [
                    (v) => {
                        return ( v >= 0 || 'ژمارەکە پێویستە لە 0 زیاتربێت')
                    }
                ]
            }
        }
    },
    methods: {
        async getdisposals(){
            try {
                this.loading = true
                const {data} = await this.axios.get('/disposal', {params:{page:this.table.page, search: this.table.search}})
                this.disposals = data.disposals
                this.table.numberOfdisposals = data.count
                this.loading = false
            } catch (error) {
                console.log(error);
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async getItemsReference(){
            try {
                const {data} = await this.axios.get('/item/forReference')
                this.itemReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async create(){
            if (this.$refs.disposal.validate()) {
                try {
                    await this.axios.post('/disposal', this.disposal)
                    this.reset()
                    this.getdisposals()
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            }
        },
        async update() {
            if (this.$refs.disposal.validate()) {
                try {
                    await this.axios.patch('/disposal/'+ this.disposal.disposal_id, this.disposal)
                    this.getdisposals()
                    this.reset()
                    this.dialog = false
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            }
        },
        remove(id){
            this.$confirm('ئایا دڵنیایی لە سڕینەوەی ئەم تەلەفە', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/disposal/'+id)
                    this.getdisposals()
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            })
        },
        reset(){
            this.disposal = {
                disposal_id: 0,
                item_id: 0,
                qty_unit: 0,
                detail: '',
                qty_item: 0,
            }
            this.$refs.disposal.resetValidation();
        },
        removeProperty({...obj} = {}, fields){
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
            return obj
        }
    },
    created() {
        this.getdisposals()
    },
    watch: {
        dialog(val){
            if (!val) {
                this.itemReference = []
                this.reset()
            }
        },
        'table.search': function () {
            this.getdisposals()
        },
        'table.page': function () {
            this.getdisposals()
        }
    },
    computed: {
        isUpdate(){
            return this.disposal.disposal_id
        }
    }
}
</script>

<style>

</style>